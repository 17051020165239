@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-primaryColor;
  }
}

html {
  height: 100%;
  width: 100%;
  height: -webkit-fill-available;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #e9edf2;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  -webkit-tap-highlight-color: transparent;
}

.zsiq_floatmain {
  margin-right: 24px;
}

@media only screen and (max-width: 768px) {
  .zsiq_floatmain {
    margin-right: 14px;
  }

  .zsiq_theme1.zsiq_floatmain {
    width: 40px !important;
  }
}

/* for input label animation */
input:not(:placeholder-shown)~.input-custom-label {
  @apply bg-white text-davyGrey transform -translate-y-[27px] translate-x-1 scale-90 opacity-100;
}

input:focus~.input-custom-label {
  @apply bg-white text-primaryColor transform -translate-y-[27px] translate-x-1 scale-90 opacity-100;
}

/* for textarea label animation */
textarea:not(:placeholder-shown)~.textarea-custom-label {
  @apply bg-white text-davyGrey transform -translate-y-[20px] translate-x-1 scale-90 opacity-100;
}

textarea:focus~.textarea-custom-label {
  @apply bg-white text-primaryColor transform -translate-y-[20px] translate-x-1 scale-90 opacity-100;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.customTransparentScroll {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.customNormalScroll {
  scrollbar-width: thin;
  scrollbar-color: #5E7799;
}

/* Chrome, Edge, and Safari */
.customTransparentScroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.customTransparentScroll::-webkit-scrollbar-track {
  background: transparent;
}

.customTransparentScroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 8px;
}

.customNormalScroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.customNormalScroll::-webkit-scrollbar-track {
  background: #dddddd;
  margin-left: 5px;
  margin-right: 5px;
}

.customNormalScroll::-webkit-scrollbar-thumb {
  background-color: #b7b8bb;
  border-radius: 8px;
}

.customDavyGreyScroll::-webkit-scrollbar {
  height: 8px;
}

.customDavyGreyScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px 10px white;
  border: solid 4px transparent;
}

.customDavyGreyScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #4B5563;
  border: solid 5px transparent;
  min-height: 60px;
}

/* to remove the up and down arrow of number input field */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customInput:checked~.checkedIcon {
  @apply flex;
}

:root {
  --app-height: 100%;
}

.full-screen {
  min-height: 100vh;
  min-height: var(--app-height);
}

.polygon.parellogram {
  -webkit-clip-path: polygon(4% 0, 100% 0, 95% 100%, 0 100%);
  clip-path: polygon(4% 0, 100% 0, 95% 100%, 0 100%);
}

.polygon.oneSideParellogram {
  -webkit-clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
}

.polygon.slandingPolygon {
  -webkit-clip-path: polygon(0 0, 100% 0, 96% 78%, 7% 100%);
  clip-path: polygon(0 0, 100% 0, 96% 78%, 7% 100%);
}

.polygon.slandingPolygon2 {
  -webkit-clip-path: polygon(12% 1%, 100% 0, 92% 66%, 0 100%);
  clip-path: polygon(12% 1%, 100% 0, 92% 66%, 0 100%);
}