.dayNumber span {
  color: #2653F1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: #F5F6F8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
  color: #FFFFFF;
  width: 26px;
  height: 26px;
  background-color: #007AFC;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
  color: #2653F1;
  background-color: #E9EDF2;
}

.rdrInRange {
  background-color: #E9EDF2;
}

.startEdge {
  left: 8px;
  background-color: #E9EDF2;
}

.startEdge span {
  background-color: #2653F1;
  border-radius: 9px;
}

.endEdge {
  right: 8px;
  background-color: #E9EDF2;
}

.endEdge span {
  background-color: #2653F1;
  border-radius: 9px;
}

.dayStartPreview {
  border-color: #2653F1;
  left: 8px;
}

.rdrDayStartOfWeek .dayStartPreview,
.rdrDayEndOfWeek .dayStartPreview,
.rdrDayStartOfMonth .dayStartPreview,
.rdrDayEndOfMonth .dayStartPreview {
  left: 8px;
}

.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrEndEdge {
  left: 8px;
  right: 8px;
}

.rdrDayEndOfMonth .rdrEndEdge,
.rdrDayEndOfWeek .rdrEndEdge {
  right: 8px;
}

.dayStartPreview.dayEndPreview {
  border-color: #2653F1;
  left: 8px;
  right: 8px;
  border-radius: 9px;
}

.dayEndPreview {
  right: 8px;
}

.rdrDayToday .rdrDayNumber span:after {
  width: 12px;
  background: #2653F1
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span:after {
  background-color: #2653F1;
}

.weekDay {
  color: #2653F1;
  font-size: 10px;
}

.monthName {
  visibility: hidden;
  color: #2653F1;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.nextPrevButton {
  background-color:  #F8F8F8 !important;
  border-radius: 8px;
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7;
}

.nextPrevButton:hover {
  background-color: #e8e7e7 !important;
}

.nextPrevButton i {
  display: block;
  width: 0;
  height: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  margin: auto;
  transform: translate(-3px, 0px);
}

.rdrStaticRange {
  border-bottom: none;
  font-size: 14px;
  line-height: 22px;
  color: #4B5563;
}

.rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: #2653F1;
  font-size: 14px;
  font-weight: 600;
}

.rdrStaticRangeLabel {
  padding: 8px;
  margin-right: 8px;
}

.rdrDefinedRangesWrapper {
  border-right: 1px solid #EDEDED;
  width: 230px;
}

.rdrStaticRanges {
  margin-top: 132px;
}

.rdrMonthAndYearWrapper {
  padding: 0;
  height: 0px;
}

.rdrMonthAndYearPickers {
  display: flex;
  flex-direction: row;
  top: 45px;
  width: 164px;
  justify-content: center;
  position: absolute;
}

.rdrPprevButton {
  position: absolute;
  top: 49px;
  margin-left: 32px;
}

.rdrPprevButton i {
  transform: rotate(135deg);
  border: solid #828282;
  border-width: 0 1px 1px 0;
  padding: 3px;
  margin-left: 9px;
}

.rdrNextButton {
  position: absolute;
  right: 0;
  margin-right: 46px;
  top: 49px;
}

.rdrNextButton i {
  transform: rotate(-45deg);
  border: solid #828282;
  border-width: 0 1px 1px 0;
  padding: 3px;
  margin-right: 9px;
}

.rdrMonth {
  border: 1px solid #E9EDF2;
  border-radius: 10px;
  padding: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 16px;
  margin-top: 18px;
}

.rdrDateRangePickerWrapper {
  padding-left: 18px;
  padding-right: 8px;
}

@media screen and (max-width: 1086px) {
  .rdrDefinedRangesWrapper {
    display: none;
  }

  #actionControls {
    display: none;
  }

  .rdrMonth {
    margin-left: 0px;
    margin-right: 0px;
  }

  .rdrPprevButton {
    margin-left: 26px;
  }

  .rdrNextButton {
    margin-right: 36px;
  }
}